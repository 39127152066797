@import 'src/styles/vars.scss';

.auth-layout {
	position: relative;
	display: flex;
	justify-content: flex-end;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 61%;
		overflow: hidden;

		@media screen and (max-width: $lg) {
			width: 50%;
		}

		@media screen and (max-width: $md) {
			display: none;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__col {
		width: 39%;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		flex-direction: column;
		background-color: #efeef1;

		@media screen and (max-width: $lg) {
			width: 50%;
			padding: 80px 0 20px;
		}

		@media screen and (max-width: $md) {
			width: 100%;
			padding: 20px 15px;
		}
	}

	&__widget {
		max-width: 448px;
		margin: 0 auto;
		width: 100%;

		@media screen and (max-width: $md) {
			max-width: 520px;
		}
	}

	&__logo {
		display: block;
		width: 200px;
		height: 58px;
		margin: 0 auto 40px;

		@media screen and (max-width: $md) {
			margin: 0 auto 63px;
		}

		@media (min-width: $sm) and (max-width: $md) {
			margin: 0 auto 150px;
		}

		img {
			display: block;
			width: 100%;
		}
	}
}
