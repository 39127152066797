@import 'src/styles/vars.scss';

.ant-modal {
	top: 45px;

	.ant-modal-close {
		background-color: var(--default);
	}

	[class*='ant-modal-content'] {
		padding: 0;
	}

	[class*='ant-modal-header'] {
		text-align: center;
		padding: 48px 48px 23px;
		margin-bottom: 0;

		.ant-modal-title {
			font-weight: 600;
			font-size: 24px;
			line-height: 24px;
			margin: 0;
		}

		@media screen and (max-width: $md) {
			padding: 16px 30px 16px 16px;

			.ant-modal-title {
				font-size: 20px;
				line-height: 1;
			}
		}
	}

	[class*='ant-modal-body'] {
		padding: 16px 48px;

		[class*='ant-form-item-control-input-content'] > .ant-input {
			padding: 8px 12px;
		}

		@media screen and (max-width: $md) {
			padding: 16px;

			[class*='ant-form-item-control-input-content'] > .ant-input {
				padding: 6px 12px;
			}
		}
	}

	[class*='ant-modal-footer'] {
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 23px 48px 48px;

		@media screen and (max-width: $md) {
			padding: 16px;
		}
	}

	[class*='ant-modal-close-icon'] {
		color: var(--mainGray);
	}

	@media screen and (max-width: $md) {
		.ant-modal-close {
			top: 5px;
			right: 5px;
		}
	}
}
