@import 'src/styles/vars.scss';
@import 'src/styles/mixins.scss';

html {
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
}

body {
	height: 100%;
	width: 100%;
	min-width: 320px;
	background-color: #efeef1;
	font-family: 'Jost', sans-serif;

	@media (min-width: $xl) {
		overflow: hidden;
	}

	@media screen and (max-width: $md) {
		background: var(--default);
	}
}

.ant-layout-header {
	background: var(--default);
}

.ant-layout-header.gray-bg {
	display: flex;
	align-items: center;

	.user {
		margin-top: 24px;
	}
}

@media print {
	.ant-layout-header {
		display: none;
	}
}

.ant-layout-content {
	background: var(--default);
}

.gray-bg:not(.transparent) {
	background-color: var(--gray1);
}

.rc-virtual-list {
	-webkit-text-size-adjust: 100%; /* Prevents automatic text size adjustment */
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}

p {
	margin: 0;
}

button {
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

.base {
	height: 100%;
}
*,
:after,
:before {
	box-sizing: border-box;
}

.quill-list {
	padding-left: 17px;
	list-style-position: outside;
}

.link {
	padding: 0;
	margin: 0;
	background: none;
	border: none;
	color: #00ac8e;
	display: inline;
	cursor: pointer;
	transition: color 0.3s;

	&:hover {
		color: #33bda5;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.container {
	width: 100%;
	max-width: 1188px;
	padding: 0 30px;
	margin: 0 auto;
}

.container-fluid {
	padding: 0 24px;
	max-width: 100%;
	margin: 0 auto;
}

.preloader-container {
	text-align: center;

	&.blockCenter {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.center {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.table-row-disabled {
	color: #a3a3a3;
}

.page-content {
	&__title {
		font-size: 24px;
		line-height: 22px;
		font-weight: 600;
		text-align: left;
		margin-bottom: 12px;

		span {
			color: #006162;
		}
	}

	&__spin {
		display: block;
		margin: 30px auto;
	}

	&__head {
		margin-bottom: 16px;
	}

	@media screen and (max-width: $md) {
		&__title {
			font-size: 18px;
			line-height: 1;
		}
	}
}

.loading-wrapper {
	height: 100%;
}

.scroll-body-modal {
	[class*='ant-modal-header'] {
		padding: 48px 0 23px;
		border-bottom: 2px solid #f0f0f0;
	}

	[class*='ant-modal-body'] {
		height: fit-content;
		max-height: 65vh;
		overflow-y: auto;

		@include custom-vertical-scrollbar;
	}

	[class*='ant-modal-footer'] {
		padding: 16px 48px 48px;
		border-top: 2px solid #f0f0f0;
	}

	@media screen and (max-width: $md) {
		[class*='ant-modal-content'] {
			max-height: calc(100svh - 20px);
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		[class*='ant-modal-header'] {
			padding: 15px 0;
		}

		[class*='ant-modal-body'] {
			max-height: initial;
			flex: 1;
		}

		[class*='ant-modal-footer'] {
			padding: 15px;
		}
	}
}

.filter-row {
	display: flex;
	margin: 0 -14px;

	&__col {
		padding: 0 14px;
	}

	&__search {
		max-width: 330px;
		width: 100%;
	}
}

.tables-scroll-container {
	@include custom-scrollbar;
}

.chart-scroll-container {
	overflow: auto;

	@media (min-width: $xl) {
		overflow: hidden auto;

		@include custom-vertical-scrollbar;
	}
}
.horizontal-chart-scroll-container {
	overflow-x: auto;

	@media (min-width: $lg) {
		overflow-y: hidden;

		@include custom-scrollbar;
	}
}

.recharts-tooltip-wrapper {
	z-index: 1;
}

.ql-size-huge {
	font-size: 32px;
}
.ql-size-large {
	font-size: 19px;
}
.ql-size-normal {
	font-size: 13px;
}
.ql-size-small {
	font-size: 9px;
}

.ql-size-huge,
.ql-size-large,
.ql-size-normal,
.ql-size-small {
	line-height: 110%;
}

.mention {
	color: var(--corvusGreen2);
}

// typography
h3,
h3.ant-typography,
div.ant-typography-h3 {
	@media screen and (max-width: $md) {
		font-size: 20px;
		line-height: 1.3;
		margin-bottom: 16px;
	}
}

:root {
	--mainGray: #8c8c8c;
	--mainYellow: #f6cf00;
	--mainOrange: #ff9900;
	--mainBlue: #46abff;
	--mainRed: #d03900;
	--mainGreen: #00cc5e;
	--default: #ffffff;

	--corvusGreen: #00ac8e;
	--corvusHover: #1db899;
	--corvusActive: #00977f;
	--corvusGreen1: #00ac8e1a;
	--corvusGreen2: #33bda5;
	--corvusGreen3: #f3f9fe;
	--corvusGreen4: #dcfaf4;
	--darkEmerald: #006162;
	--darkEmeraldLight: #eff4f3;
	--darkEmeraldHover: #007d7d;
	--darkEmeraldActive: #004d4d;
	--stickerColor: #fcedb1;
	--corvusGreenLight: #f2fbf9;
	--pinkLight: #fbe6fc;

	--error: #d03900;
	--warning: #e89900;

	--jira: #2684ff;

	--gray: #fafafa;
	--gray1: #efeef1;
	--gray2: #e7e6e7;
	--gray3: #828282;
	--gray4: #f0f0f0;
	--gray5: #5b5b5b;
	--gray6: #d9d9d9;
	--gray7: #fbfbfb;
	--gray8: #bfbfbf;
	--gray9: #f5f5f5;
	--gray10: #aaaaab;
	--gray11: #f9f9f9;
	--gray12: #a3a3a3;
	--gray13: #f4f4f4;
	--gray14: #f6f6f6;

	--black: #000000;
	--black1: #262626;
	--black2: #1a2026;

	--pinkLight: #fbe6fc;

	--bluePressed: #0080ea;
	--blueLight: #e1f6ff;

	--orange: #ff9b25;
	--orangeLight: #fff4ee;

	--sticky: #fcedb1;
	--brown: #6b4016;

	--descoped: #1d49b1;
	--descopedLight: #ecf2ff;
	--done: #285f61;
	--doneLight: #dcf4f5;
	--inProgress: #4da98f;
	--inProgressLight: #e3fff7;
	--notStarted: #7c99f4;
	--notStartedLight: #f0f3ff;
	--onHold: #52bed2;
	--onHoldLight: #edfcff;
	--pendingApproval: #75a635;
	--pendingApprovalLight: #f4ffe6;

	--sidebarZIndex: 10003;
	//the biggest z index in the app
	--maxZIndex: 10002;
	//next the biggest z index in the app after notifications popup
	--notesChatZIndex: 10001;
	--notesChatSecondaryZIndex: 10000;
	--mentionMenuZIndex: 9999;
	--boardListWidth: 300px;
	--headerZIndex: 998;
	--floatingButtonGroupZIndex: 997;
}
