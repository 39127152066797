[class*='ant-input-search'] {
	//set search height
	& > span > span {
		height: 40px;
	}
}

[class*='ant-input-affix-wrapper'] {
	padding: 8px 12px;
}

.ant-input-number .ant-input-number-input {
	height: 38px;
}

[class*='ant-input-password-icon'] > svg {
	fill: var(--black2);
}
[class*='ant-input-number-focused']:not(.ant-input-number-status-error)
	> .ant-input-number-input-wrap {
	box-shadow: 0px 0px 4px 0px var(--corvusGreen);
}

[class*='ant-input']:focus:not(.ant-input-number-input):not(
		.ant-input-number-status-error,
		.ant-input-status-error
	) {
	box-shadow: 0px 0px 4px 0px var(--corvusGreen);
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
	color: var(--gray5);
	background-color: var(--gray2);
}

.ant-input-group-addon {
	color: var(--black2) !important;
	background-color: var(--gray) !important;
}

.ant-col-md-4 {
	.ant-input {
		padding: 8px 12px;
	}
}

.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: var(--gray9);
	border-color: var(--gray6) !important;
}
