@import 'src/styles/vars.scss';

@mixin empty-page-text {
	color: var(--mainGray);
	text-align: center;
	font-size: 24px;
	font-weight: 400;
	line-height: 22px;

	@media screen and (max-width: $md) {
		font-size: 18px;
		line-height: 1.3;
	}
}

@mixin custom-scrollbar($color: var(--default), $size: 12px) {
	@media (min-width: $lg) {
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: $size;
		}

		&::-webkit-scrollbar:horizontal {
			height: $size;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid $color;
			background-color: rgba(130, 130, 130, 0.35);

			&:hover {
				background-color: rgba(130, 130, 130, 0.5);
			}
		}
	}
}

@mixin large-title {
	font-size: 36px;
	font-weight: 600;
	line-height: 40px;
}

@mixin title2 {
	font-size: 32px;
	font-weight: 600;
	line-height: 22px;
}

@mixin title {
	font-weight: 600;
	font-size: 24px;
	line-height: 22px;
}

@mixin form-label {
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}

@mixin sub-title {
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
}

@mixin steps {
	font-size: 22px;
	font-weight: 600;
	line-height: 22px;
	color: var(--darkEmerald);
}

@mixin text {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

@mixin custom-vertical-scrollbar {
	@media (min-width: $lg) {
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 11px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			border: 2px solid var(--default);
			background-color: #00ac8e;
		}
	}
}

@mixin table-content-align {
	th {
		white-space: nowrap;
	}

	tr {
		vertical-align: top;
	}
}

@mixin table-container($withTabs: false) {
	overflow-y: auto;
	overflow-x: visible;
	height: calc(100vh - 190px);

	@if $withTabs == true {
		height: calc(100vh - 236px);
	}
}

@mixin flow-chart-right-sidebar-data-selection {
	[class*='ant-collapse-header'] {
		font-size: 20px;
		font-weight: 600;
		padding-left: 0 !important;
		border-radius: 0 !important;
		border-bottom: 1px solid var(--gray4);
	}

	[class*='ant-collapse-content-box'] {
		padding: 12px 0;
	}
	[class*='ant-collapse-header-text'] {
		border-bottom: none;
	}

	.collapseChild {
		[class*='ant-collapse-header'] {
			padding-left: 12px !important;
			font-size: 14px;
			font-weight: 500;
			border-bottom: 1px solid var(--gray6);
		}
		[class*='ant-collapse-header-text'] {
			border-bottom: none;
		}

		.collapseContent {
			display: flex;
			flex-direction: column;
		}
	}

	.collapseChild[class*='ant-collapse-item-active'] {
		border-bottom: 1px solid var(--gray6);
	}
}

@mixin project-health-status {
	.healthWrapper {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.healthColor {
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}

	.onTrack {
		background-color: var(--mainGreen);
	}

	.atRisk {
		background-color: var(--mainYellow);
	}

	.offTrack {
		background-color: var(--mainRed);
	}

	.default {
		background-color: transparent;
	}
}

@mixin time-picker-apply-button {
	[class*='ant-picker-date-panel'] {
		width: 100%;
		max-width: 344px;
	}

	[class*='ant-picker-content'] {
		width: 100%;
	}

	[class*='ant-picker-time-panel-column'] {
		&::after {
			height: 20px;
		}
	}

	[class*='ant-picker-ok'] {
		display: inline-flex !important;
		& > button {
			padding: 0 7px;
		}
		& > button > span {
			width: 40px;
			position: relative;
			font-size: 0;
		}

		& > button > span:after {
			content: 'Apply';
			font-size: 14px;
			position: absolute;
			inset: 0;
		}
	}

	@media screen and (max-width: $md) {
		[class*='ant-picker-date-panel'] {
			max-width: 205px;
			top: -7px;
			left: 4%;
		}
	}
}

@mixin custom-steps-component {
	margin-bottom: 24px;
	border-bottom: 1px solid var(--gray2);

	[class*='ant-steps-finish-icon'] {
		svg {
			fill: var(--corvusGreen);
		}
	}

	[class*='ant-steps-item-finish'] {
		[class*='ant-steps-item-icon'] {
			background-color: var(--corvusGreenLight);
		}
	}

	:global(.ant-steps-item-active) {
		:global(.ant-steps-item-title) {
			font-weight: 500;
		}
	}

	@media (min-width: $lg) {
		[class*='ant-steps-item-container'] {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		:global(.ant-steps-item-title) {
			font-size: 18px;
		}
	}
}

@mixin icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--gray2);
	width: 28px;
	height: 28px;
	cursor: pointer;
}

@mixin onboarding-title {
	color: var(--darkEmerald);
	font-size: 46px;
	font-weight: 500;
	line-height: 40px;
}

@mixin responsive-col {
	flex: 1 1 0% !important;
	max-width: 100% !important;
	width: auto !important;
}

@mixin comment-icon-reverse {
	transform: scaleX(-1) translateY(2px);
	-webkit-transform: scaleX(-1) translateY(2px);
	-moz-transform: scaleX(-1) translateY(2px);
	-ms-transform: scaleX(-1) translateY(2px);
	-o-transform: scaleX(-1) translateY(2px);
}
