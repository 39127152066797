@import './ant-custom-styles-by-component/input.scss';
@import './ant-custom-styles-by-component/modal.scss';
@import './ant-custom-styles-by-component/select.scss';
@import './ant-custom-styles-by-component/form.scss';
@import './ant-custom-styles-by-component/button.scss';
@import 'src/styles/vars.scss';

[class*='ant-breadcrumb-link'] {
	a {
		color: var(--black2);
	}
	a:hover {
		background-color: transparent;
	}
}

[class*='anticon-ellipsis'] {
	font-size: 30px;
}

[class*='ant-table-thead'] {
	//table column header
	& > tr > th {
		font-size: 16px;
		line-height: 22px;
		font-weight: 600;
		border-bottom: none !important;

		.ant-table-column-title {
			font-size: 16px;
		}
	}
}

.ant-layout-header {
	@media screen and (max-width: $md) {
		height: 55px;
	}
}

.ant-dropdown-button {
	@media screen and (max-width: $md) {
		.ant-btn.ant-btn-icon-only {
			width: auto;
			padding: 5px;
		}
	}
}

.ant-table-sticky-scroll {
	border: none;
}

.ant-table-sticky-scroll-bar {
	opacity: 0;
}

.ant-table {
	.ant-table-body {
		overflow-y: auto !important;
	}
}

.ant-table-body {
	&::-webkit-scrollbar {
		-webkit-appearance: none !important;
	}

	&::-webkit-scrollbar:vertical {
		width: 11px !important;
	}

	&::-webkit-scrollbar:horizontal {
		height: 11px !important;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px !important;
		border: 2px solid var(--default) !important;
		background-color: rgba(130, 130, 130, 0.35) !important;
	}
}

.ant-picker-dropdown .ant-picker-cell-disabled {
	color: var(--gray8);
	pointer-events: none;
}
.ant-picker-dropdown .ant-picker-cell-disabled::before {
	opacity: 0.1;
}

.ant-picker {
	padding: 8px 12px;
}

.ant-tooltip .ant-tooltip-inner {
	color: var(--black2);
}

.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within:not(.ant-picker-status-error) {
	box-shadow: 0px 0px 4px 0px #00ac8e !important;
}

.ant-avatar {
	min-width: 32px;
}

.ant-alert-error {
	.ant-alert-message {
		color: var(--error);
	}
}

.ant-message {
	font-family: 'Jost', sans-serif;
}

.ant-avatar-string {
	font-size: 18px;
}

.ant-tooltip {
	max-width: 395px;
}

.ant-picker-outlined.ant-picker-disabled,
.ant-picker-outlined[disabled] {
	background-color: var(--gray2);
	color: var(--gray5);
}

.ant-input-number-outlined.ant-input-number-disabled,
.ant-input-number-outlined[disabled] {
	background-color: var(--gray2);
	color: var(--gray5);
}

.ant-progress {
	max-width: 300px;
}
.upload-file-wrapper .upload-file-list .upload-file-list-item:hover {
	background-color: transparent;
}

.upload-file-wrapper
	.upload-file-list
	.upload-file-list-item
	.upload-file-list-item-actions
	.upload-file-list-item-action {
	opacity: 1;
	.anticon-close {
		color: var(--mainRed);
	}
}

.ant-btn {
	@media screen and (max-width: $md) {
		padding: 8px;
	}
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
	background-color: transparent;
}

.upload-file-wrapper .upload-file-list .upload-file-list-item .upload-file-icon .anticon {
	color: var(--black2);
}

.upload-file-list-item-container > .upload-file-list-item {
	margin-top: 14px !important;
}

.upload-file-wrapper .upload-file-list .upload-file-list-item .upload-file-list-item-name {
	overflow: hidden;
	white-space: wrap !important;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}

.upload-file-list-item-error {
	display: flex;
	gap: 8px;
	margin-top: 14px !important;
	height: fit-content;

	.upload-file-icon > .anticon-paper-clip {
		color: var(--gray5) !important;
	}

	.upload-file-list-item-name {
		overflow: hidden;
		padding: 0 !important;
		overflow: hidden;
		white-space: wrap !important;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		width: 100%;
		cursor: default;
	}
}

.upload-file-list-item-actions {
	min-width: fit-content;

	.ant-btn {
		width: fit-content;
	}
}

.invalid-file-error {
	display: flex;
	align-items: center;

	.removeIcon-text {
		font-weight: 400;
		margin-right: 6px;
		color: var(--error);
	}
}

.ant-menu-submenu-popup > .ant-menu {
	border-radius: 6px;
}

.ant-picker-dropdown
	.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell-selected
	.ant-picker-time-panel-cell-inner {
	background-color: #d3ebe3;
}

.ant-picker-outlined.ant-picker-disabled:hover:not([disabled]),
.ant-picker-outlined[disabled]:hover:not([disabled]) {
	color: var(--gray5);
	background-color: var(--gray2);
}

.ant-input-number-outlined.ant-input-number-disabled:hover:not([disabled]),
.ant-input-number-outlined[disabled]:hover:not([disabled]) {
	color: var(--gray5);
	background-color: var(--gray2);
}

.ant-upload-wrapper {
	display: inline-block;
	width: 100%;
}

.ant-upload-wrapper .ant-upload-select {
	width: 100%;
}

.ant-card .ant-card-head-title {
	text-align: center;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
	width: 100%;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	display: none;
}

.ant-skeleton.skeleton-full-size {
	&,
	.ant-skeleton-image {
		width: 100%;
		height: 100%;
	}
}

.ant-dropdown-menu {
	min-width: 150px;
}

.ant-pagination {
	align-items: center;
}

.ant-btn-icon {
	display: flex;
}

.ant-form-item {
	@media screen and (max-width: $md) {
		margin-bottom: 8px;
	}
}
